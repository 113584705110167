.dashboard-table-wrap {
  overflow: auto;
  padding: 0 2rem;
  margin: 1rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // text-align: center;

  .dashboard-submitted-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
  }

  table {
    .cell-date {
      width: 150px;
    }

    .cell-actions {
      width: 205px;
      text-decoration: underline;
      cursor: pointer;
    }

    .icon-wrapper {
      width: 100%;
      height: 10rem;
      text-align: center;
    }
  }
}
