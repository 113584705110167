.request-reset-container {
  padding: 1.25rem;

  .reset-wrapper {
    // height: 20rem;
    min-height: 20rem;
    text-align: center;

    .login-link {
      color: #74a2d3;
    }
    .naf-logo {
      width: 15rem;
      height: 4rem;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
