.facility-container {
  padding: 1.25rem;

  .facility-content {
    text-align: center;

    /* padding-top: 1rem; */

    @media screen and (max-width: 599.95px) {
      .MuiTabs-scrollButtonsDesktop {
        display: inline-flex;
      }
    }
    .paper-header {
      padding: 1rem 1.5rem;
      text-align: center;
    }
    .formcontrol-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10rem;
    }
  }
}
