.login-container {
  padding: 1.25rem;

  .page-content {
    text-align: center;
    /* padding-top: 1rem; */

    @media screen and (max-width: 599.95px) {
      .MuiTabs-scrollButtonsDesktop {
        display: inline-flex;
      }
    }
    .login-message-container {
      border: 1px solid red;
      margin: 1rem;
      text-align: center;
    }

    .page-header {
      padding: 1rem 1.5rem;
      text-align: center;
    }

    .login-form {
      width: 266px;
      margin: 0 auto;

      .login-btn {
        margin: 16px 8px;
        padding-bottom: 15px;
      }
    }
    .naf-logo {
      margin-top: 0.5rem;
      width: 15rem;
      height: 4rem;
    }
  }
}
