.dashboard-content {
  // z-index: -1;
}
.dashboard-submitted-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 1.5rem 0;
}
.dashboard-import {
  text-align: right;
  padding: 0.5rem;
}
.dashboard-list {
  display: flex;
  justify-content: center;
}
.spinner-content {
  width: 100%;
  height: 100vh;
  position: fixed;
  // left: 100%;
  z-index: 1;
  background-color: rgba(211, 211, 211, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
